import React from 'react';
import Layout from '../components/Layout';

import tw from 'twin.macro';
import Seo from '../components/SEO';
import { Helmet } from 'react-helmet';
const Heading = tw.h1`text-3xl font-bold tracking-tight text-primary-700 dark:text-white`;
const Title = tw.h2`block max-w-2xl mt-4 text-xl text-gray-500 dark:text-gray-300`;

const Appointment = ({
  heading = 'Book an Appointment',
  title = (
    <>
      Schedule an appointment with a <span tw="text-primary-500">CountMyCents </span> representative
      for personalized introduction to our plans and how it works.
    </>
  ),
}) => {
  // const { page } = useStaticQuery(contactQuery);
  return (
    <Layout>
      <Seo
        title="Book an Appointment"
        description="Schedule an appointment with a CountMyCents representative for a personalized introduction to our plans and how it works."
      />
      <Helmet>
        <script
          src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          type="text/javascript"
        />
      </Helmet>

      <section tw="bg-white dark:bg-gray-900 mt-16">
        <div tw="container flex flex-col items-center px-4 py-12 mx-auto text-center">
          <Heading>{heading}</Heading>
          <Title>{title}</Title>
          </div>
      </section>
          <div tw="mt-6">
            <div
              class="meetings-iframe-container"
              data-src="https://meetings.hubspot.com/narinder-deol?embed=true"
            >
              {}
            </div>
          </div>
        
    </Layout>
  );
};

export default Appointment;
